// eslint-disable-next-line
import React from "react";

const Landing = (props) => {
  return "";
};

Landing.propTypes = {};

export default Landing;
