export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//Test Rule Types
export const VBR_TESTRULE_LOADING = "VBR_TESTRULE_LOADING";
export const VBR_TESTRULE_ERROR = "VBR_TESTRULE_ERROR";
export const VBR_TESTRULE_RESULT = "VBR_TESTRULE_RESULT";
export const VBR_TESTRULE_RETURNTOP21 = "VBR_TESTRULE_RETURNTOP21";

//Item Search
export const VBR_ITEMSEARCH_LOADING = "VBR_ITEMSEARCH_LOADING";
export const VBR_ITEMSEARCH_ERROR = "VBR_ITEMSEARCH_ERROR";
export const VBR_ITEMSEARCH_RESULT = "VBR_ITEMSEARCH_RESULT";
export const VBR_ITEMSEARCH_RETURNTOP21 = "VBR_ITEMSEARCH_RETURNTOP21";
export const VBR_ITEMSEARCH_ASTYPE_SHOW = "VBR_ITEMSEARCH_ASTYPE_SHOW";
export const VBR_ITEMSEARCH_ASTYPE_HIDE = "VBR_ITEMSEARCH_ASTYPE_HIDE";
export const VBR_ITEMSEARCH_ASTYPE_CLEAR = "VBR_ITEMSEARCH_ASTYPE_CLEAR";
export const VBR_ITEMSEARCH_ASTYPE_RESULT = "VBR_ITEMSEARCH_ASTYPE_RESULT";
export const VBR_ITEMSEARCH_FULLTEXT_LOADING =
  "VBR_ITEMSEARCH_FULLTEXT_LOADING";
export const VBR_ITEMSEARCH_FULLTEXT_RESULT = "VBR_ITEMSEARCH_FULLTEXT_RESULT";
export const VBR_ITEMSEARCH_LOADING_PRODDETAILS =
  "VBR_ITEMSEARCH_LOADING_PRODDETAILS";
export const VBR_ITEMSEARCH_GET_PRODDETAILS = "VBR_ITEMSEARCH_GET_PRODDETAILS";
export const VBR_ITEMSEARCH_GET_P21_PRICEAVAIL =
  "VBR_ITEMSEARCH_GET_P21_PRICEAVAIL";
export const VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL =
  "VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL";
export const VBR_ITEMSEARCH_GET_P21_QTY = "VBR_ITEMSEARCH_GET_P21_QTY";

//Spox PO Status
export const VBR_SPOXPOSTATUS_LOADING = "VBR_SPOXPOSTATUS_LOADING";
export const VBR_SPOXPOSTATUS_ERROR = "VBR_SPOXPOSTATUS_ERROR";
export const VBR_SPOXPOSTATUS_RESULT = "VBR_SPOXPOSTATUS_RESULT";
export const VBR_SPOXPOSTATUS_RETURNTOP21 = "VBR_SPOXPOSTATUS_RETURNTOP21";
export const VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING =
  "VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING";
export const VBR_SPOXPOSTATUS_EMAILPOPUP_DATA =
  "VBR_SPOXPOSTATUS_EMAILPOPUP_DATA";
export const VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE =
  "VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE";
