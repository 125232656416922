import {
  VBR_TESTRULE_LOADING,
  VBR_TESTRULE_ERROR,
  VBR_TESTRULE_RESULT,
  VBR_TESTRULE_RETURNTOP21,
} from '../actions/types';

const initialState = {
  loading: true,
  reqData: {},
  flagToReturnToP21: false,
  vbrKeyVerified: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VBR_TESTRULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VBR_TESTRULE_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case VBR_TESTRULE_RESULT:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        reqData: payload,
        vbrKeyVerified: true,
      };
    case VBR_TESTRULE_RETURNTOP21:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        flagToReturnToP21: true,
      };
    default:
      return state;
  }
}
