import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner/Spinner";
import ItemSearchReturnForm from "./ItemSearch_returnForm";
import { ExtractVarValues } from "../utilities/ExtractVars";
import {
  saveData,
  getProductDetails,
  saveDataAndReturnToP21,
  getP21PriceAvail,
} from "../../../actions/vbr_itemsearch";

import ProdFuzzyMatchSingleOverlaySec1 from "./ProdFuzzyMatch_singleOverlay_sec1";
import ProdFuzzyMatchSingleOverlaySec2 from "./ProdFuzzyMatch_singleOverlay_sec2";
import ProdFuzzyMatchSingleOverlaySec3 from "./ProdFuzzyMatch_singleOverlay_sec3";
import ProdFuzzyMatchSingleOverlaySec4 from "./ProdFuzzyMatch_singleOverlay_sec4";

import "./ItemSearchHead.css";

const ItemSearch_details = ({
  saveData,
  _VBRItemSearchState,
  getProductDetails,
  saveDataAndReturnToP21,
  getP21PriceAvail,
}) => {
  const [formData, setFormData] = useState({});
  const {
    reqData,
    flagToReturnToP21 = false,
    vbrKeyVerified = false,
    productDetails = {},
  } = _VBRItemSearchState;

  const { loading = true, p21Data = {} } = productDetails;
  const { item_desc: prodTitle = "" } = p21Data;

  const { vbrkey, prodID = "" } = useParams();
  const history = useHistory();

  //load our product details from the back end
  //also get the price / stock of this unit
  useEffect(() => {
    setFormData(reqData);
    getProductDetails(prodID);
    // eslint-disable-next-line
  }, [prodID, setFormData, getProductDetails]);

  useEffect(() => {
    if (
      !loading &&
      p21Data !== null &&
      p21Data.item_id !== null &&
      p21Data.inv_mast_uid !== null
    ) {
      //get our variables for our pricing call
      const {
        customer_id = "",
        sales_loc_id = "",
        ship_to_id = "",
      } = ExtractVarValues(
        ["customer_id", "sales_loc_id", "ship_to_id"],
        formData
      );

      getP21PriceAvail(
        p21Data.item_id,
        p21Data.inv_mast_uid,
        customer_id,
        ship_to_id,
        sales_loc_id
      );
    }
    // eslint-disable-next-line
  }, [loading, p21Data, getP21PriceAvail]);

  //programmatic conditions to get the user back to P21
  //Disable for Debugging
  useEffect(() => {
    if (!loading && flagToReturnToP21) {
      console.log("should have redirected");

      document.getElementById("returnForm").submit();
      return;
    }

    if (!loading && !vbrKeyVerified) {
      document.getElementById("returnForm").submit();
      return;
    }
  }, [loading, flagToReturnToP21, vbrKeyVerified]);

  //return to P21 via a javascript tag that gets generated server side
  //this prevents the CORS errors from inside REACT/NODE
  const returnToP21Redirect = (e = null) => {
    if (e !== null && e !== undefined) e.preventDefault();

    //get empty form to be the "POST" method back to the return to P21 call
    //NOTE: This call does NOT save any data into the request object at all...
    document.getElementById("returnForm").submit();
    return;
  };

  const onClickAddToP21 = (
    formData = {},
    item_id = "",
    priceUOM = "",
    unitPrice = 0.0
  ) => {
    const getEmptyIndexPos = (fieldName = "", tempFormData = {}) => {
      if (tempFormData.fieldList === undefined) return -1;
      let indexPos = -1;
      tempFormData.fieldList.forEach((obj, i) => {
        if (
          obj.fieldName === fieldName &&
          (typeof obj.fieldValue === "object" || obj.fieldValue === 0)
        ) {
          indexPos = i;
        }
      });
      return indexPos;
    };

    const setNewRow = (tempFormData = {}) => {
      if (tempFormData.fieldList === undefined) return tempFormData;

      tempFormData.fieldList.push({
        className: "d_dw_oe_line_dataentry_9",
        fieldTitle: "Item ID  B$",
        fieldName: "oe_order_item_id",
        fieldAlias: {},
        fieldValue: item_id,
        modifiedFlag: "Y",
        readOnly: "N",
        rowID: -1,
        dataType: "char(40)",
        triggerField: "N",
        triggerRow: "N",
        fieldOriginalValue: {},
        updateSequence: 0,
        setFocus: "N",
        baseClassName: "d_dw_oe_line_dataentry",
        newRow: "Y",
        allowCascade: "N",
      });

      tempFormData.fieldList.push({
        className: "d_dw_oe_line_dataentry_9",
        fieldTitle: "UOM  B$",
        fieldName: "unit_of_measure",
        fieldAlias: {},
        fieldValue: priceUOM,
        modifiedFlag: "Y",
        readOnly: "N",
        rowID: -1,
        dataType: "varchar(255)",
        triggerField: "N",
        triggerRow: "N",
        fieldOriginalValue: {},
        updateSequence: 0,
        setFocus: "N",
        baseClassName: "d_dw_oe_line_dataentry",
        newRow: "Y",
        allowCascade: "N",
      });

      tempFormData.fieldList.push({
        className: "d_dw_oe_line_dataentry_9",
        fieldTitle: "B$  Qty Ordered",
        fieldName: "unit_quantity",
        fieldAlias: {},
        fieldValue: "1",
        modifiedFlag: "Y",
        readOnly: "N",
        rowID: -1,
        dataType: "decimal(9)",
        triggerField: "N",
        triggerRow: "N",
        fieldOriginalValue: {},
        updateSequence: 0,
        setFocus: "Y",
        baseClassName: "d_dw_oe_line_dataentry",
        newRow: "Y",
        allowCascade: "N",
      });

      /*
      tempFormData.fieldList.push({
        className: "d_dw_oe_line_dataentry_9",
        fieldTitle: "B$  Qty Ordered",
        fieldName: "unit_price",
        fieldAlias: {},
        fieldValue: unitPrice,
        modifiedFlag: "Y",
        readOnly: "N",
        rowID: -1,
        dataType: "decimal(9)",
        triggerField: "N",
        triggerRow: "N",
        fieldOriginalValue: {},
        updateSequence: 0,
        setFocus: "N",
        baseClassName: "d_dw_oe_line_dataentry",
        newRow: "Y",
        allowCascade: "N",
      });
      */

      return tempFormData;
    };

    const setEmptyRow = (tempFormData = {}) => {
      if (tempFormData.fieldList === undefined) return tempFormData;

      //set our new item properties
      const oe_order_item_id_index = getEmptyIndexPos(
        "oe_order_item_id",
        tempFormData
      );
      tempFormData.fieldList[oe_order_item_id_index] = {
        ...tempFormData.fieldList[oe_order_item_id_index],
        fieldValue: item_id,
        modifiedFlag: "Y",
      };

      const unit_of_measure_index = getEmptyIndexPos(
        "unit_of_measure",
        tempFormData
      );
      tempFormData.fieldList[unit_of_measure_index] = {
        ...tempFormData.fieldList[unit_of_measure_index],
        fieldValue: priceUOM,
        modifiedFlag: "Y",
      };

      const unit_quantity_index = getEmptyIndexPos(
        "unit_quantity",
        tempFormData
      );
      tempFormData.fieldList[unit_quantity_index] = {
        ...tempFormData.fieldList[unit_quantity_index],
        fieldValue: 1,
        modifiedFlag: "Y",
        setFocus: "Y",
      };

      /*
      //WE HAVE TO PRICE the OBJECT otherwise P21 leaves price as 0.00
      const unit_price_index = getEmptyIndexPos("unit_price", tempFormData);
      tempFormData.fieldList[unit_price_index] = {
        ...tempFormData.fieldList[unit_price_index],
        fieldValue: unitPrice,
        modifiedFlag: "Y",
      };
      */
      return tempFormData;
    };

    //pull a copy of the formData state so we can modify it
    let tempFormData = formData;
    if (tempFormData === undefined || tempFormData.fieldList === undefined)
      return;

    if (getEmptyIndexPos("oe_order_item_id", tempFormData) < 0) {
      //no empty row is available
      //set a brand new row to the order
      tempFormData = setNewRow(tempFormData);
    } else {
      //we have an empty row we can use
      //set our new item properties
      tempFormData = setEmptyRow(tempFormData);
    }

    setFormData(tempFormData);
    saveDataAndReturnToP21(vbrkey, tempFormData);
  };

  const searchFeedbackOnClick = () => {};
  const searchAnalytics = {};

  return loading ? (
    <div className="vbr_itemSearch-outer">
      <div className="header-outer">
        <br />
        <br />
      </div>
      <Spinner />
    </div>
  ) : (
    <div className="vbr_itemSearch-outer">
      <div className="header-outer">
        <br />
        <br />
      </div>
      <div className="vbr-inner">
        <div className="breadcrumbs">
          <span className="breadcrumb" onClick={(e) => returnToP21Redirect(e)}>
            P21 Order Entry
          </span>
          {" > "}
          <span className="breadcrumb" onClick={() => history.goBack()}>
            Item Search
          </span>
          {" > "}
          <span className="breadcrumb">Item Details</span>
        </div>

        <div id="ProdFuzzyMatch_overlay-inner">
          <ProdFuzzyMatchSingleOverlaySec1
            search_state={_VBRItemSearchState}
            prodTitle={prodTitle}
            searchFeedbackOnClick={searchFeedbackOnClick}
            searchAnalytics={searchAnalytics}
            onClickAddToP21={onClickAddToP21}
          />
          <ProdFuzzyMatchSingleOverlaySec2 search_state={_VBRItemSearchState} />
          <ProdFuzzyMatchSingleOverlaySec3 search_state={_VBRItemSearchState} />
          <ProdFuzzyMatchSingleOverlaySec4 search_state={_VBRItemSearchState} />
        </div>
      </div>
      <ItemSearchReturnForm vbrkey={vbrkey} />
    </div>
  );
};

ItemSearch_details.propTypes = {};

const mapStateToProps = (state) => ({
  _VBRItemSearchState: state.VBRItemSearch,
});

export default connect(mapStateToProps, {
  saveData,
  getProductDetails,
  saveDataAndReturnToP21,
  getP21PriceAvail,
})(ItemSearch_details);
