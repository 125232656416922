import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

const SpoxPoStatus_poDetails = ({ poData }) => {
  const {
    date_last_modified = null,
    _shipToConfirmed = false,
    _agreedToTerms = false,
    spoxInitSent = null,
    spoxEnabled = false,
    poNo = 0,
  } = poData;
  return (
    <Fragment>
      <div
        style={{ marginBottom: "10px", fontSize: "16px", fontWeight: "bold" }}
      >
        Stellar Industrial PO #{poNo}
      </div>
      <table width="100%">
        <tbody>
          <tr>
            <td valign="top">Date Last Modified:</td>
            <td valign="top">
              <Moment format="M/D/Y">{date_last_modified}</Moment>
            </td>
            <td valign="top">Supplier Agreed To Terms:</td>
            <td valign="top">{_agreedToTerms ? "Yes" : "Not Yet"}</td>
          </tr>
          <tr>
            <td valign="top">Spox Initial Email Sent:</td>
            <td valign="top">
              <Moment format="M/D/Y">{spoxInitSent}</Moment>
            </td>
            <td valign="top">Ship To Confirmed:</td>
            <td valign="top">{_shipToConfirmed ? "Yes" : "Not Yet"}</td>
          </tr>
          <tr>
            <td valign="top">SPOX Enabled (Location/Supplier/PO):</td>
            <td valign="top">
              {spoxEnabled ? "Enabled" : "Not Enabled"}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

SpoxPoStatus_poDetails.propTypes = {};

export default SpoxPoStatus_poDetails;
