import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../layout/Spinner/Spinner";
import { formatMoney } from "../../utils/currencyFormat";
import { isEven } from "../../utils/generalUtils";

import miniLoading from "./miniLoading.svg";
//import ProdFuzzyMatchSingleProdDetails from './ProdFuzzyMatch_singleProdDetails';

import "./Pagination.css";

const ItemSearch_results = ({
  resultsObj = {},
  resultsP21 = {},
  onMouseUpSingleSearchResultItem,
  searchFeedbackOnClick,
  searchAnalytics = {},
  onPaginationClick,
}) => {
  const {
    fullTextLoading = true,
    searchResultsArr = [],
    searchTerm = "",
    searchPartNumber = "",
    pag = 1,
    limit = 20,
    productCountTotal = 0,
    p21Info = {},
  } = resultsObj;

  const { analyticsSubmitted = false } = searchAnalytics;
  const { vbrkey } = useParams();

  if (searchTerm === "" && searchPartNumber === "") {
    return null;
  }

  const detailsLinkURL = `/vbr_ui/itemsearch/prodDetails/${vbrkey}`;

  return (
    <div className="singleProdSearchResult-outer">
      <div className="searchProd_title-outer">
        <div className="secTitle">
          Product Search Suggestions For: <br />"{searchPartNumber}
          {searchPartNumber !== "" && searchTerm !== "" && " "}
          {searchTerm}"
        </div>
        <div className="secFeedback">
          {!analyticsSubmitted && (
            <Fragment>
              Did you find a good match?
              <div className="secFeedback-float">
                <i
                  className="far fa-thumbs-up"
                  onClick={(e) => searchFeedbackOnClick(true)}
                />
                <i
                  className="far fa-thumbs-down"
                  onClick={(e) => searchFeedbackOnClick(false)}
                />
              </div>
            </Fragment>
          )}
          {analyticsSubmitted && (
            <Fragment>Thank you for your feedback...</Fragment>
          )}
        </div>
      </div>

      <div className="resultTable-outer">
        <table className="tableDisplay" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <td valign="top">Part Number</td>
              <td valign="top">P21 Description</td>
              <td valign="top" style={{ textAlign: "center" }}>
                <span className="desktopOnly">Qty On Hand</span>
              </td>
              <td
                valign="top"
                style={{ textAlign: "center" }}
                className="desktopOnlyCell"
              >
                STARS / AD
              </td>
              <td valign="top" className="desktopOnlyCell">
                Manufacturer
              </td>
            </tr>
          </thead>
          <tbody>
            {!fullTextLoading && searchResultsArr.length === 0 && (
              <tr>
                <td colSpan="5">No Products Found</td>
                <td></td>
              </tr>
            )}
            {!fullTextLoading &&
              searchResultsArr.length > 0 &&
              searchResultsArr.map((row, index) => {
                const {
                  _id = "",
                  item_id = "",
                  description = "",
                  ad = false,
                  stars = false,
                  inv_mast_uid = "",
                  manufacturer_name = "",
                  manu_part_number = "",
                  //_score = 0,
                } = row;

                return (
                  <Fragment key={uuidv4()}>
                    <tr className={isEven(index) ? "odd" : "even"}>
                      <td>
                        <Link to={`${detailsLinkURL}/${_id}`}>
                          {item_id !== "" && item_id}
                          {item_id === "" && (
                            <div style={{ opacity: ".8" }}>
                              <i>MFG #:</i> {manu_part_number}
                            </div>
                          )}
                        </Link>
                      </td>
                      <td>
                        <Link to={`${detailsLinkURL}/${_id}`}>
                          {description}
                        </Link>
                      </td>
                      {inv_mast_uid === null || inv_mast_uid === "" ? (
                        <Fragment>
                          <td style={{ textAlign: "center" }}>-</td>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {p21Info !== null &&
                          p21Info !== undefined &&
                          p21Info[inv_mast_uid] !== undefined &&
                          p21Info[inv_mast_uid] !== null &&
                          p21Info[inv_mast_uid].hasOwnProperty(
                            "qty_on_hand"
                          ) ? (
                            <Fragment>
                              {p21Info[inv_mast_uid]["qty_on_hand"] > 0 ? (
                                <td style={{ textAlign: "center" }}>
                                  <Link to={`${detailsLinkURL}/${_id}`}>
                                    {formatMoney(
                                      p21Info[inv_mast_uid]["qty_on_hand"],
                                      0
                                    )}
                                    {p21Info[inv_mast_uid].hasOwnProperty(
                                      "uom"
                                    ) && (
                                      <Fragment>
                                        {" "}
                                        {p21Info[inv_mast_uid].uom}
                                      </Fragment>
                                    )}
                                  </Link>
                                </td>
                              ) : (
                                <td
                                  style={{
                                    opacity: ".4",
                                    textAlign: "center",
                                  }}
                                >
                                  <Link to={`${detailsLinkURL}/${_id}`}>
                                    {formatMoney(
                                      p21Info[inv_mast_uid]["qty_on_hand"],
                                      0
                                    )}
                                    {p21Info[inv_mast_uid].hasOwnProperty(
                                      "uom"
                                    ) && (
                                      <Fragment>
                                        {" "}
                                        {p21Info[inv_mast_uid].uom}
                                      </Fragment>
                                    )}
                                  </Link>
                                </td>
                              )}
                            </Fragment>
                          ) : (
                            <td style={{ textAlign: "center" }}>
                              <img src={miniLoading} alt="" />
                            </td>
                          )}
                        </Fragment>
                      )}

                      <td
                        style={{ textAlign: "center" }}
                        className="desktopOnlyCell"
                      >
                        <Link to={`${detailsLinkURL}/${_id}`}>
                          {stars && ad && <span>STARS + AD</span>}
                          {stars && !ad && <span>STARS</span>}
                          {!stars && ad && <span>AD</span>}
                        </Link>
                      </td>
                      <td className="desktopOnlyCell">
                        <Link to={`${detailsLinkURL}/${_id}`}>
                          {manufacturer_name !== null
                            ? manufacturer_name.replace(/\b\w/g, (c) =>
                                c.toUpperCase()
                              )
                            : manufacturer_name}
                        </Link>
                      </td>
                    </tr>

                    {p21Info !== null &&
                      p21Info !== undefined &&
                      p21Info[inv_mast_uid] !== undefined &&
                      p21Info[inv_mast_uid] !== null &&
                      p21Info[inv_mast_uid].hasOwnProperty(
                        "pricingDisplay"
                      ) && (
                        <tr
                          className={
                            isEven(index)
                              ? "odd desktopOnlyRow"
                              : "even desktopOnlyRow"
                          }
                        >
                          <td colSpan="5">
                            {p21Info[inv_mast_uid].pricingLoading ? (
                              <div className="pricingLoading blinking">
                                Loading
                              </div>
                            ) : (
                              /*<ProdFuzzyMatchSingleProdDetails
                                elasticInfo={row}
                                p21Info={p21Info[inv_mast_uid]}
                              />*/
                              <div>DETAILS WOULD GO HERE</div>
                            )}
                          </td>
                        </tr>
                      )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>

        {!fullTextLoading &&
          searchResultsArr.length !== 0 &&
          productCountTotal > limit && (
            <div className="paginationDiv">
              <Pagination
                prevPageText="prev"
                nextPageText="next"
                firstPageText="first"
                lastPageText="last"
                activePage={parseInt(pag)}
                itemsCountPerPage={limit}
                totalItemsCount={productCountTotal}
                onChange={onPaginationClick}
              />
            </div>
          )}
        {fullTextLoading && <Spinner />}
      </div>
    </div>
  );
};

ItemSearch_results.propTypes = {
  resultsObj: PropTypes.object.isRequired,
  onMouseUpSingleSearchResultItem: PropTypes.func.isRequired,
  searchFeedbackOnClick: PropTypes.func.isRequired,
  searchAnalytics: PropTypes.object.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
};

export default ItemSearch_results;
