import axios from "axios";
import {
  VBR_ITEMSEARCH_LOADING,
  VBR_ITEMSEARCH_ERROR,
  VBR_ITEMSEARCH_RESULT,
  VBR_ITEMSEARCH_RETURNTOP21,
  VBR_ITEMSEARCH_ASTYPE_SHOW,
  VBR_ITEMSEARCH_ASTYPE_HIDE,
  VBR_ITEMSEARCH_ASTYPE_CLEAR,
  VBR_ITEMSEARCH_ASTYPE_RESULT,
  VBR_ITEMSEARCH_FULLTEXT_LOADING,
  VBR_ITEMSEARCH_FULLTEXT_RESULT,
  VBR_ITEMSEARCH_LOADING_PRODDETAILS,
  VBR_ITEMSEARCH_GET_PRODDETAILS,
  VBR_ITEMSEARCH_GET_P21_PRICEAVAIL,
  VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL,
  VBR_ITEMSEARCH_GET_P21_QTY,
} from "./types";

/* P21 VBR Action Items */
export const loadData =
  (vbrkey = "") =>
  async (dispatch) => {
    dispatch({
      type: VBR_ITEMSEARCH_LOADING,
      payload: {},
    });

    //get our data from the back end
    try {
      const res = await axios.get(`/vbr/itemsearch/data/${vbrkey}`);

      console.log(res);

      dispatch({
        type: VBR_ITEMSEARCH_RESULT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VBR_ITEMSEARCH_ERROR,
        payload: {
          error,
        },
      });
    }
  };

export const saveDataAndReturnToP21 =
  (vbrkey = "", reqData = {}) =>
  async (dispatch) => {
    dispatch({
      type: VBR_ITEMSEARCH_LOADING,
      payload: {},
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      reqData,
    };

    //get our data from the back end
    try {
      // eslint-disable-next-line
      const res = await axios.put(
        `/vbr/itemsearch/data/${vbrkey}`,
        body,
        config
      );

      //send the user back to P21 via this dispatch
      dispatch({
        type: VBR_ITEMSEARCH_RETURNTOP21,
        payload: {},
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VBR_ITEMSEARCH_ERROR,
        payload: {
          error,
        },
      });
    }
  };

export const saveData =
  (vbrkey = "", reqData = {}) =>
  async (dispatch) => {
    dispatch({
      type: VBR_ITEMSEARCH_LOADING,
      payload: {},
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      reqData,
    };

    //get our data from the back end
    try {
      const res = await axios.put(
        `/vbr/itemsearch/data/${vbrkey}`,
        body,
        config
      );

      dispatch({
        type: VBR_ITEMSEARCH_RESULT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VBR_ITEMSEARCH_ERROR,
        payload: {
          error,
        },
      });
    }
  };

/* Rule Specific Action Items */
//place held
export const searchFullText =
  (
    formData = {},
    _searchAnalyticsID = 0,
    newAnalyticsRecord = false,
    pag = 1
  ) =>
  async (dispatch) => {
    console.log(`formData`);
    console.log(formData);

    //get our options to send along with this payload
    let {
      adStarsSearchFilter = "starsandad_preferred",
      searchLoose = "true",
      stockableFilter = "false",
      searchBarPartNumber = "",
      searchTerm = "",
      limit = 30,
    } = formData;

    //remove any pound signs before we send the search
    searchTerm = searchTerm.replace("#", "NO");

    let payload = {
      searchTerm,
      searchPartNumber: searchBarPartNumber,
      searchResultsArr: [],
      adStarsSearchFilter: adStarsSearchFilter,
      pag,
      limit,
      productCountTotal: 0,
      newAnalyticsRecord,
    };

    //flag that we are loading, this is for the UI
    await dispatch({ type: VBR_ITEMSEARCH_FULLTEXT_LOADING, payload: payload });

    if (searchTerm.length >= 2 || searchBarPartNumber.length >= 2) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        searchTerm: searchTerm,
        searchPartNumber: payload.searchPartNumber,
        adStarsSearchFilter: payload.adStarsSearchFilter,
        searchLoose,
        stockableFilter,
        pag: payload.pag,
        limit: payload.limit,
        newAnalyticsRecord,
      };

      const res = await axios.post(`/vbr/itemsearch/fullText/`, body, config);

      //check what just came back, set defaults if null
      const {
        searchResultsArr = [],
        adStarsSearchFilter = "starsandad_preferred",
        pag = 1,
        limit = 15,
        productCountTotal = 0,
        searchAnalyticsID = 0,
      } = res.data;

      //throw into the payload
      payload = {
        ...payload,
        searchTerm,
        searchResultsArr,
        adStarsSearchFilter,
        pag,
        limit,
        productCountTotal,
        searchAnalyticsID,
      };

      //if we aren't getting new analytics then the res data will not have a valid analytics ID anymore
      if (!newAnalyticsRecord) {
        payload.searchAnalyticsID = _searchAnalyticsID;
      }
    }
    dispatch({ type: VBR_ITEMSEARCH_FULLTEXT_RESULT, payload });
    try {
    } catch (err) {
      dispatch({ type: VBR_ITEMSEARCH_FULLTEXT_RESULT, payload });
    }
  };

export const searchAsType =
  (searchTerm = "") =>
  async (dispatch) => {
    let payload = {
      asYouTypeArray: [],
    };
    try {
      let res;

      if (searchTerm.length >= 2 && searchTerm.length < 25) {
        let searchTermModified = searchTerm.replace("#", "NO");
        res = await axios.get(`/vbr/itemsearch/asType/${searchTermModified}`);

        //check what just came back, set a default if null, store in the payload
        const { asYouTypeArray = [] } = res.data;
        payload.asYouTypeArray = asYouTypeArray;
        dispatch({ type: VBR_ITEMSEARCH_ASTYPE_RESULT, payload });
      } else {
        dispatch({ type: VBR_ITEMSEARCH_ASTYPE_CLEAR, payload });
      }
    } catch (err) {
      dispatch({ type: VBR_ITEMSEARCH_ASTYPE_CLEAR, payload });
    }
  };

export const clearSearchAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({
    type: VBR_ITEMSEARCH_ASTYPE_CLEAR,
    payload,
  });
};

export const showAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: VBR_ITEMSEARCH_ASTYPE_SHOW, payload });
};

export const hideAsType = (keyName) => async (dispatch) => {
  let payload = {
    keyName,
  };
  dispatch({ type: VBR_ITEMSEARCH_ASTYPE_HIDE, payload });
};

export const getProductDetails = (_id) => async (dispatch) => {
  try {
    //update the UI so it knows we are working on it
    dispatch({ type: VBR_ITEMSEARCH_LOADING_PRODDETAILS, payload: {} });
    const res = await axios.get(`/vbr/itemsearch/prodDetails/${_id}`);

    //check what just came back, set defaults if null
    dispatch({ type: VBR_ITEMSEARCH_GET_PRODDETAILS, payload: res.data });
  } catch (error) {}
};

export const getP21PriceAvail =
  (
    itemID,
    inv_mast_uid,
    customerID = 127279,
    shipToID = 127279,
    salesLocID = 1
  ) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        customerID,
        shipToID,
        salesLocID,
        products: [itemID],
      };

      let payload = {
        p21Info: {
          [inv_mast_uid]: {
            pricingLoading: true,
            pricingDisplay: true,
          },
        },
      };

      //update the UI so it knows we are working on it
      dispatch({
        type: VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL,
        payload: payload,
      });

      const res = await axios.post(
        `/vbr/itemsearch/priceAvailability`,
        body,
        config
      );

      let tempPayload = res.data;

      //set loading to false for each object we just got
      for (let key of Object.keys(tempPayload)) {
        tempPayload[key].pricingLoading = false;
      }

      //rewrite out payload so we can update the UI
      payload = {
        p21Info: tempPayload,
      };

      dispatch({
        type: VBR_ITEMSEARCH_GET_P21_PRICEAVAIL,
        payload: payload,
      });
    } catch (err) {}
  };

export const searchQtyOnHand =
  (searchResultsArr = []) =>
  async (dispatch) => {
    try {
      if (
        searchResultsArr === null ||
        searchResultsArr === undefined ||
        searchResultsArr.length === 0
      ) {
        //reset our qtyOnHandData
        return;
      }

      const productsToLookup = [];

      //break apart our results array into just the inv_mast_uids
      searchResultsArr.forEach((searchResult) => {
        productsToLookup.push(searchResult.inv_mast_uid);
      });

      //push our array of itemIDs to the middleware server
      let res;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        products: productsToLookup,
      };

      res = await axios.post(`/vbr/itemsearch/qtyOnHand`, body, config);
      let payload = {
        p21Info: res.data.data,
      };

      dispatch({ type: VBR_ITEMSEARCH_GET_P21_QTY, payload: payload });
    } catch (error) {}
  };
