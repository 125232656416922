import React from "react";

const ItemSearch_returnForm = (props) => {
  return (
    <form
      method="POST"
      action={`/vbr/itemsearch/return/${props.vbrkey}`}
      id="returnForm"
    ></form>
  );
};

ItemSearch_returnForm.propTypes = {};

export default ItemSearch_returnForm;
