import React, { Fragment } from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";

const supplierPortalURI = process.env.REACT_APP_SUPPLIERPORTALURI;

const { formatMoney } = require("../../utils/currencyFormat");

const SpoxPOStatus_poPreview = ({ poData = {} }) => {
  const {
    poNo = 0,
    _id = "",
    lines = [],
    _publicAccessCode = "",
    supplierID = 0,
  } = poData;

  let poSubTotal = 0;
  return (
    <div>
      <div className="poPreview-top">
        <div>
          <div className="title">SPOX Purchase Order Details</div>
          <div className="lastUpdated">&nbsp;</div>
        </div>
        <div>&nbsp;</div>
      </div>

      <table width="100%" cellSpacing="2" cellPadding="0">
        <thead>
          <tr>
            <td
              width="45px"
              style={{
                padding: "5px 0px",
                fontSize: "13px",
                textAlign: "center",
                background: "#cccccc",
              }}
            >
              Line
            </td>
            <td
              width="45px"
              style={{
                padding: "5px 0px",
                fontSize: "13px",
                textAlign: "center",
                background: "#cccccc",
              }}
            >
              UOM
            </td>
            <td
              style={{
                background: "#cccccc",
                fontSize: "13px",
                paddingLeft: "10px",
              }}
            >
              Description
            </td>
            <td
              width="130px"
              style={{
                background: "#cccccc",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              Unit Price
            </td>
            <td
              width="110px"
              style={{
                background: "#cccccc",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              Line Total
            </td>
          </tr>
        </thead>
        <tbody>
          {
            //begin line items going here
            lines.map((line, index) => {
              const {
                _id: lineKey,
                poLineNo = 0,
                itemID = "",
                itemDesc = "",
                supplierPartNo = "",
                qtyOrdered = 0,
                acknowledged = false,
                complete = false,
                supplierShipped = false,
                requiredDate = null,
                UOM = "",
                unitPrice = 0,
                pricingUnitSize = "",
                pricingUnit = "",
              } = line;

              poSubTotal = poSubTotal + qtyOrdered * unitPrice;
              const tdStyle = {
                textAlign: "center",
                fontSize: "13px",
                paddingTop: "10px",
              };
              const tdStyle2 = {
                padding: "10px 10px",
                textAlign: "left",
                fontSize: "13px",
              };

              if (index % 2) {
                tdStyle.background = "#ccc";
                tdStyle2.background = "#ccc";
              }

              return (
                <Fragment key={lineKey}>
                  <tr>
                    <td style={{ ...tdStyle }} valign="top">
                      {poLineNo}
                    </td>
                    <td style={{ ...tdStyle }} valign="top">
                      {qtyOrdered} {UOM}
                    </td>
                    <td style={{ ...tdStyle2 }} valign="top">
                      <div>
                        <b>{itemID}</b>
                      </div>
                      <div>{itemDesc}</div>
                      <Moment format="MM/DD/YY">{requiredDate}</Moment>
                      <div>
                        Status:{" "}
                        <a
                          href={`${supplierPortalURI}SupplierPO_Remote/${_id}/${_publicAccessCode}/${poNo}/${supplierID}`}
                          target="_blank"
                        >
                          {_lineStatus(line)}
                        </a>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "10px",
                        background: "#eeeeee",
                      }}
                      valign="top"
                    >
                      ${formatMoney(unitPrice)} / {formatMoney(pricingUnitSize)}{" "}
                      {pricingUnit}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        background: "#eeeeee",
                        fontSize: "13px",
                        paddingTop: "10px",
                        paddingRight: "10px",
                      }}
                      valign="top"
                    >
                      ${formatMoney(unitPrice * qtyOrdered)}
                    </td>
                  </tr>
                </Fragment>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

const _lineStatus = (line = {}) => {
  const {
    complete = false,
    qtyReceived = 0,
    remainingQty = 0,
    supplierShipped = false,
    acknowledged = false,
    cancelFlag = false,
    deleteFlag = false,
  } = line;

  if (deleteFlag || cancelFlag) {
    return "Line Deleted/Canceled";
  }
  if (complete) {
    return "Line Complete";
  }
  if (qtyReceived > 0 && remainingQty > 0) {
    return "Line Confirmed, Partial Items Received";
  }
  if (supplierShipped) {
    return "Line Confirmed, Shipped";
  }
  if (acknowledged) {
    return "Line Confirmed, Awaiting Shipment";
  }

  return "Unconfirmed Line";
};

SpoxPOStatus_poPreview.propTypes = {};

export default SpoxPOStatus_poPreview;
