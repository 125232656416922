import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { ExtractVarValues } from "../utilities/ExtractVars";
import {
  loadData,
  saveData,
  viewEmail,
  hideEmail,
  sendPOEmail,
} from "../../../actions/vbr_spoxpostatus";
import Spinner from "../../layout/Spinner/Spinner";
import SpoxPOStatusReturnForm from "./SpoxPOStatus_returnForm";
import "./SpoxPOStatus.css";

import PODetails from "./SpoxPoStatus_poDetails";
import POEmailLog from "./SpoxPOStatus_emailLog";
import POPreview from "./SpoxPOStatus_poPreview";
import POEmailViewer from "./SpoxPOStatus_emailViewer";

// retrieve variables from configuration files
const supplierPortalURI = process.env.REACT_APP_SUPPLIERPORTALURI;

const SpoxPOStatus = ({
  loadData,
  saveData,
  viewEmail,
  hideEmail,
  sendPOEmail,
  _VBRSpoxPOStatus,
  match,
}) => {
  const [formData, setFormData] = useState({});
  const {
    loading = true,
    reqData,
    poData = {},
    emailLog = [],
    flagToReturnToP21 = false,
    errorMsg = "",
    vbrKeyVerified = false,
    viewEmail: viewEmailState = {},
  } = _VBRSpoxPOStatus;

  const { poNo } = poData;
  const { hidden: viewEmailHidden = true } = viewEmailState;

  const { vbrkey } = useParams();
  const { mail_address1 = "", mail_address2 = "" } = ExtractVarValues(
    ["mail_address1", "mail_address2"],
    formData
  );

  /*
  const {
    mail_address1_props = {},
    mail_address2_props = {},
  } = extractVarsExtended(['mail_address1', 'mail_address2'], formData);
  */

  //load initial data set from the server
  useEffect(() => {
    loadData(vbrkey);
  }, [vbrkey]);

  //update the local state with the data that was received from the server
  useEffect(() => {
    if (!loading) {
      setFormData(reqData);
    }
  }, [reqData, loading]);

  //programmatic conditions to get the user back to P21
  //Disable for Debugging
  /*
  useEffect(() => {
    if (!loading && flagToReturnToP21) {
      console.log("should have redirected");

      document.getElementById("returnForm").submit();
      return;
    }

    if (!loading && !vbrKeyVerified) {
      document.getElementById("returnForm").submit();
      return;
    }
  }, [loading, flagToReturnToP21, vbrKeyVerified]);
  */

  //return to P21 via a javascript tag that gets generated server side
  //this prevents the CORS errors from inside REACT/NODE
  const returnToP21Redirect = (e = null) => {
    if (e !== null && e !== undefined) e.preventDefault();

    //get empty form to be the "POST" method back to the return to P21 call
    //NOTE: This call does NOT save any data into the request object at all...
    document.getElementById("returnForm").submit();
    return;
  };

  //save the data to our storage method
  const saveDataToServer = (e) => {
    saveData(vbrkey, formData);
  };

  const changeForm = (e) => {
    try {
      //set the mailing address to something different
      let _dataIndex = formData.fieldList.findIndex(
        (obj) => obj.fieldName === e.target.name
      );

      let tempData = formData.fieldList;
      tempData[_dataIndex].fieldValue = e.target.value;
      tempData[_dataIndex].modifiedFlag = "Y";
      setFormData({ fieldList: tempData });
    } catch (error) {
      console.log(error);
    }
  };

  const externalPOUrl = () => {
    const { _id = "", _publicAccessCode = "", supplierID = 0 } = poData;
    return `${supplierPortalURI}SupplierPO_Remote/${_id}/${_publicAccessCode}/${poNo}/${supplierID}`;
  };

  const onClickViewEmail = (_id = "") => {
    viewEmail(_id);
  };

  const onClickCloseViewEmail = (e) => {
    if (e.target.id === "SpoxPOEmailViewer-outer") hideEmail();
  };

  const onClickSendPOAckEmail = () => {
    sendPOEmail(vbrkey, poNo, "acknowledge");
  };

  const onClickSendPOExpediteEmail = () => {
    sendPOEmail(vbrkey, poNo, "expedite");
  };

  return loading ? (
    <div className="vbr_spoxPOStatus-outer">
      <div className="header-outer">
        <br />
        <br />
      </div>
      <Spinner />
    </div>
  ) : (
    <Fragment>
      {!viewEmailHidden && (
        <POEmailViewer
          viewEmailState={viewEmailState}
          onClickCloseViewEmail={onClickCloseViewEmail}
        />
      )}
      <div className="vbr_spoxPOStatus-outer">
        <div className="header-outer">
          {viewEmailHidden}
          <span>
            <a href={externalPOUrl()} target="_blank" rel="noopener">
              View SPOX PO
            </a>
          </span>
        </div>
        <div className="vbr-inner">
          <div className="breadcrumbs">
            <span
              className="breadcrumb"
              onClick={(e) => returnToP21Redirect(e)}
            >
              P21 Purchase Order Entry
            </span>{" "}
            {"> "}
            <span className="breadcrumb">SPOX PO Status/Details</span>
          </div>
          <br />
          <div id="top-outer">
            <div className="flexDiv">
              <PODetails poData={poData} />
            </div>
            <div className="flexDiv">
              <POEmailLog
                poData={poData}
                emailLog={emailLog}
                onClickViewEmail={onClickViewEmail}
              />
            </div>
          </div>
          <div id="bottom-outer">
            <POPreview poData={poData} />
          </div>
        </div>
        <br />
        <SpoxPOStatusReturnForm vbrkey={vbrkey} />
      </div>
    </Fragment>
  );
};

SpoxPOStatus.propTypes = {};

const mapStateToProps = (state) => ({
  _VBRSpoxPOStatus: state.VBRSpoxPOStatus,
});

export default connect(mapStateToProps, {
  loadData,
  saveData,
  viewEmail,
  hideEmail,
  sendPOEmail,
})(SpoxPOStatus);
