import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { htmlSpecialsEncode } from "../../utils/generalUtils";
import {
  loadData,
  saveData,
  saveDataAndReturnToP21,
  searchAsType,
  searchFullText,
  hideAsType,
  clearSearchAsType,
  searchQtyOnHand,
} from "../../../actions/vbr_itemsearch";
import Spinner from "../../layout/Spinner/Spinner";
import ItemSearchReturnForm from "./ItemSearch_returnForm";
import ItemSearchResults from "./ItemSearch_results";
import "./ItemSearchHead.css";
import "./ItemSearch.css";

const ItemSearch = ({
  loadData,
  saveData,
  saveDataAndReturnToP21,
  searchAsType,
  searchFullText,
  hideAsType,
  clearSearchAsType,
  searchQtyOnHand,
  _VBRItemSearchState,
}) => {
  const [formData, setFormData] = useState({});
  const [singleSearchFormData, setSingleSearchFormData] = useState({
    searchTerm: "",
    searchBarPartNumber: "",
    pag: 0,
  });

  const {
    loading = true,
    reqData,
    flagToReturnToP21 = false,
    vbrKeyVerified = false,
    asYouTypeArray = [],
    showAsYouType = false,
    // eslint-disable-next-line
    p21Info = {},
    searchResultsArr = [],
  } = _VBRItemSearchState;

  const { vbrkey } = useParams();
  const {
    searchTerm = "",
    searchBarPartNumber = "",
    adStarsSearchFilter = "starsandad_preferred",
    searchLoose = true,
    stockableFilter = false,
    showAdvancedOptions = false,
  } = singleSearchFormData;

  //load initial data set from the server
  useEffect(() => {
    loadData(vbrkey);
  }, [vbrkey, loadData]);

  //update the local state with the data that was received from the server
  useEffect(() => {
    if (!loading) {
      setFormData(reqData);
    }
  }, [reqData, loading, setFormData]);

  //programmatic conditions to get the user back to P21
  useEffect(() => {
    if (!loading && flagToReturnToP21) {
      console.log("should have redirected");

      document.getElementById("returnForm").submit();
      return;
    }

    if (!loading && !vbrKeyVerified) {
      document.getElementById("returnForm").submit();
      return;
    }
  }, [loading, flagToReturnToP21, vbrKeyVerified]);

  //fetch the qty on hand out of P21 when we get a new results list
  useEffect(() => {
    if (searchResultsArr !== null && searchResultsArr.length > 0) {
      searchQtyOnHand(_VBRItemSearchState.searchResultsArr);
    }
    // eslint-disable-next-line
  }, [searchResultsArr]);

  //add a new object to our formData object
  // eslint-disable-next-line
  const addObjectToFormData = () => {
    const getEmptyIndexPos = (fieldName = "", tempFormData = {}) => {
      if (tempFormData.fieldList === undefined) return -1;
      let indexPos = -1;
      tempFormData.fieldList.forEach((obj, i) => {
        if (
          obj.fieldName === fieldName &&
          (typeof obj.fieldValue === "object" || obj.fieldValue === 0)
        ) {
          indexPos = i;
        }
      });
      return indexPos;
    };

    const setNewItemEmptyRow = (tempFormData = {}) => {
      if (tempFormData.fieldList === undefined) return tempFormData;

      //set our new item properties
      const oe_order_item_id_index = getEmptyIndexPos(
        "oe_order_item_id",
        tempFormData
      );
      tempFormData.fieldList[oe_order_item_id_index] = {
        ...tempFormData.fieldList[oe_order_item_id_index],
        fieldValue: "GREMO GMG-7007",
        modifiedFlag: "Y",
      };

      const unit_of_measure_index = getEmptyIndexPos(
        "unit_of_measure",
        tempFormData
      );
      tempFormData.fieldList[unit_of_measure_index] = {
        ...tempFormData.fieldList[unit_of_measure_index],
        fieldValue: "EA",
        modifiedFlag: "Y",
      };

      const unit_quantity_index = getEmptyIndexPos(
        "unit_quantity",
        tempFormData
      );
      tempFormData.fieldList[unit_quantity_index] = {
        ...tempFormData.fieldList[unit_quantity_index],
        fieldValue: 1,
        modifiedFlag: "Y",
      };

      /* WE HAVE TO PRICE the OBJECT otherwise P21 leaves price as 0.00
        const unit_price_index = getEmptyIndexPos("unit_price", tempFormData);
        tempFormData.fieldList[unit_price_index] = {
          ...tempFormData.fieldList[unit_price_index],
          fieldValue: 10.99,
          modifiedFlag: "Y",
        };
        */

      return tempFormData;
    };

    //pull a copy of the formData state so we can modify it
    let tempFormData = formData;
    if (tempFormData === undefined || tempFormData.fieldList === undefined)
      return;

    if (getEmptyIndexPos("oe_order_item_id", tempFormData) < 0) {
      //no empty row is available
      console.log("no empty row available for use");
    } else {
      //we have an empty row we can use
      console.log("empty row we can add");
      //set our new item properties
      tempFormData = setNewItemEmptyRow(tempFormData);
    }

    setFormData(tempFormData);
    saveDataAndReturnToP21(vbrkey, tempFormData);
  };

  //return to P21 via a javascript tag that gets generated server side
  //this prevents the CORS errors from inside REACT/NODE
  const returnToP21Redirect = (e = null) => {
    if (e !== null && e !== undefined) e.preventDefault();

    //get empty form to be the "POST" method back to the return to P21 call
    //NOTE: This call does NOT save any data into the request object at all...
    document.getElementById("returnForm").submit();
    return;
  };

  //save the data to our storage method
  // eslint-disable-next-line
  const saveDataToServer = (e) => {
    saveData(vbrkey, formData);
  };

  //place held
  //let showAsYouType = false;
  //let asYouTypeArray = ["term", "another term", "a third term"];
  let searchAnalyticsID = ""; //this should probably be pulled from the P21 reqData

  const onSearchChange = (e) => {
    let newValue = e.target.value.replace("#", "NO");
    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: newValue,
    });

    searchAsType(htmlSpecialsEncode(newValue), e.target.name);
  };
  const onSearchKeyPress = (e) => {
    const { searchTerm = "", searchBarPartNumber = "" } = singleSearchFormData;
    if (e.key === "Enter") {
      if (searchTerm.length > 1 || searchBarPartNumber.length > 1) {
        document.activeElement.blur();
        hideAsType();
        searchFullText(singleSearchFormData, searchAnalyticsID, true, 1);

        setSingleSearchFormData({
          ...singleSearchFormData,
          pag: 1,
        });

        /*
        setSearchAnalytics({
          ...searchAnalytics,
          analyticsSubmitted: false,
        });
        */
      }
    }
  };
  const onClickSearchButton = (e) => {
    const { searchTerm = "", searchBarPartNumber = "" } = singleSearchFormData;
    if (searchTerm.length <= 1 && searchBarPartNumber.length <= 1) return;

    document.activeElement.blur();
    hideAsType();
    searchFullText(singleSearchFormData, searchAnalyticsID, true, 1);

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag: 1,
    });

    /*
    setSearchAnalytics({
      ...searchAnalytics,
      analyticsSubmitted: false,
    });
    */
  };
  const onClickAsYouTypeDiv = (searchTerm) => {
    setSingleSearchFormData({ searchTerm });
    clearSearchAsType();
    //setSearchBar('');
    document.activeElement.blur();
    hideAsType();
    searchFullText(singleSearchFormData, searchAnalyticsID, true, 1);

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag: 1,
    });

    /*
    setSearchAnalytics({
      ...searchAnalytics,
      analyticsSubmitted: false,
    });
    */
  };
  const onChangeSingleSearchFormData = (e) => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      [e.target.name]: e.target.value,
    });
  };
  const toggleSingleAdvancedOptions = () => {
    setSingleSearchFormData({
      ...singleSearchFormData,
      showAdvancedOptions: !singleSearchFormData.showAdvancedOptions,
    });
  };

  //event bubbled escape to close the as you type div
  const closeAsYouTypeDiv = (e, action) => {
    //only do something if the showAsYouType is being displayed
    if (showAsYouType) {
      if (e.target.name === "search") {
      } else {
        e.preventDefault();
        hideAsType();
      }
    }
  };

  const onPaginationClick = (pag) => {
    document.activeElement.blur();
    hideAsType();

    setSingleSearchFormData({
      ...singleSearchFormData,
      pag,
    });

    searchFullText(singleSearchFormData, searchAnalyticsID, false, pag);
  };

  //place held
  const onMouseUpSingleSearchResultItem = () => {};
  const searchFeedbackOnClick = () => {};
  const searchAnalytics = () => {};

  return loading ? (
    <div id="vbr_itemSearch-outer">
      <div className="header-outer">
        <br />
        <br />
      </div>
      <Spinner />
    </div>
  ) : (
    <Fragment>
      <div
        className="vbr_itemSearch-outer"
        onClick={(e) => closeAsYouTypeDiv(e)}
      >
        <div className="header-outer">
          <br />
          <br />
        </div>
        <div className="vbr-inner">
          <div className="breadcrumbs">
            <span
              className="breadcrumb"
              onClick={(e) => returnToP21Redirect(e)}
            >
              P21 Order Entry
            </span>{" "}
            {"> "}
            <span className="breadcrumb">Item Search</span>
          </div>
          <br />
          <div className="secTitle">Search For Product Suggestions:</div>
          <div className="singleProductSearch-outer">
            <input
              type="text"
              name="searchBarPartNumber"
              value={searchBarPartNumber}
              placeholder="Part Number"
              id="searchBarPartNumber"
              onChange={(e) => onSearchChange(e)}
              onKeyPress={(e) => onSearchKeyPress(e)}
            />
            <input
              type="text"
              name="searchTerm"
              value={searchTerm}
              placeholder="Product Description"
              onChange={(e) => onSearchChange(e)}
              onClick={(e) => onSearchChange(e)}
              onKeyPress={(e) => onSearchKeyPress(e)}
            />
            <button onClick={(e) => onClickSearchButton(e)}>Search</button>
          </div>
          <div
            className={
              showAsYouType
                ? "searchAsTypeAnchor-outer"
                : "searchAsTypeAnchor-outer hide"
            }
          >
            {asYouTypeArray && asYouTypeArray.length > 0 && (
              <div className="searchAsTypeAnchor">
                {asYouTypeArray.map((termRes) => {
                  return (
                    <div
                      key={uuidv4()}
                      onClick={(e) => onClickAsYouTypeDiv(termRes)}
                    >
                      {termRes}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={
              showAdvancedOptions
                ? "singleSearchAdvancedOptions-outer"
                : "singleSearchAdvancedOptions-outer hide"
            }
          >
            <div className="options-flex-outer">
              <div className="options-flex">
                <div className="label">STARS & AD Search:</div>
                <select
                  name="adStarsSearchFilter"
                  value={adStarsSearchFilter}
                  onChange={(e) => onChangeSingleSearchFormData(e)}
                >
                  <option value="general">Show All Products</option>
                  <option value="starsandad_preferred">
                    STARS + AD Preferred
                  </option>
                  <option value="starsandad_only">STARS + AD Only</option>
                  <option value="stars_only">STARS Only</option>
                  <option value="ad_only">AD Only</option>
                </select>
              </div>
              <div className="options-flex">
                <div className="label">Stockable Items</div>
                <select
                  name="stockableFilter"
                  value={stockableFilter}
                  onChange={(e) => onChangeSingleSearchFormData(e)}
                >
                  <option value="true">Show Only Stockable Items</option>
                  <option value="false">Show All Items</option>
                </select>
              </div>
              <div className="options-flex">
                <div className="label">Search Strategy:</div>
                <select
                  name="searchLoose"
                  value={searchLoose}
                  onChange={(e) => onChangeSingleSearchFormData(e)}
                >
                  <option value="true">Loose Matches</option>
                  <option value="false">Strict Matches</option>
                </select>
              </div>
            </div>
          </div>

          <div className="toggleAdvancedOptions">
            <span onClick={() => toggleSingleAdvancedOptions()}>
              {showAdvancedOptions
                ? "Hide Advanced Search Options"
                : "Show Advanced Search Options"}
            </span>
          </div>
          <ItemSearchResults
            resultsObj={_VBRItemSearchState}
            onMouseUpSingleSearchResultItem={onMouseUpSingleSearchResultItem}
            searchFeedbackOnClick={searchFeedbackOnClick}
            searchAnalytics={searchAnalytics}
            onPaginationClick={onPaginationClick}
          />
        </div>
        <br />
        <ItemSearchReturnForm vbrkey={vbrkey} />
      </div>
    </Fragment>
  );
};

ItemSearch.propTypes = {};

const mapStateToProps = (state) => ({
  _VBRItemSearchState: state.VBRItemSearch,
});

export default connect(mapStateToProps, {
  loadData,
  saveData,
  saveDataAndReturnToP21,
  searchAsType,
  searchFullText,
  hideAsType,
  clearSearchAsType,
  searchQtyOnHand,
})(ItemSearch);
