import React, { Fragment } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";
import "./App.css";
import "./TableDisplay.css";
import "./TableDivCSS.css";

import Alert from "./components/layout/Alert";
import Landing from "./components/layout/Landing/Landing";
import ItemSearchInit from "./components/visualRules/ItemSearch/ItemSearch";
import ItemSearchDetails from "./components/visualRules/ItemSearch/ItemSearch_details";
import SpoxPOStatus from "./components/visualRules/SpoxPOStatus/SpoxPOStatus";

const App = () => (
  <Provider store={store}>
    <Router>
      <Fragment>
        <Alert />
        <Switch>
          <Route
            exact
            path="/vbr_ui/itemsearch/init/:vbrkey"
            component={ItemSearchInit}
          />
          <Route
            exact
            path="/vbr_ui/itemsearch/prodDetails/:vbrkey/:prodID"
            component={ItemSearchDetails}
          />
          <Route
            exact
            path="/vbr_ui/spoxpostatus/init/:vbrkey"
            component={SpoxPOStatus}
          />
          <Route exact path="/" component={Landing} />
        </Switch>
      </Fragment>
    </Router>
  </Provider>
);

export default App;
