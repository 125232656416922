import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

import Spinner from "../../layout/Spinner/Spinner";
import "./SpoxPOStatus_emailerViewer.css";
import Moment from "react-moment";

const SpoxPOStatus_emailViewer = ({
  viewEmailState = {},
  onClickCloseViewEmail,
}) => {
  const { loading = true, data = {} } = viewEmailState;
  const { dateSent = null, to = "", subject = "", msg = "", type = "" } = data;

  // <div className="closeButton" >
  //         <i className="far fa-times-circle"></i>
  //       </div>

  return (
    <div id="SpoxPOEmailViewer-outer" onClick={(e) => onClickCloseViewEmail(e)}>
      <div className="inner">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="title">SPOX Sent Email Viewer</div>
            <div className="topInfo">
              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                className="emailViewerTable"
              >
                <tbody>
                  <tr>
                    <td>
                      <b>Date Sent:</b>
                    </td>
                    <td>
                      <Moment format={"MM/DD/YYYY h:i:s"}>{dateSent}</Moment>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>To:</b>
                    </td>
                    <td>{to}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Message Type:</b>
                    </td>
                    <td>{type}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Subject:</b>
                    </td>
                    <td>{subject}</td>
                  </tr>
                </tbody>
              </table>

              <b>Message:</b>
              <br />
              <hr />
            </div>
            <div className="messageDiv">{ReactHtmlParser(msg)}</div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

SpoxPOStatus_emailViewer.propTypes = {};

export default SpoxPOStatus_emailViewer;
