import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import ImgShadow from "../../layout/ImgShadow/ImgShadow";

const ProductImg = ({
  assets,
  altText = "",
  single = false,
  onClick = () => {},
}) => {
  if (assets && (assets.length > 0 || assets.imageFile)) {
    if (single) {
      return (
        <a
          href={`https://assets.stellarindustrial.com/2/ITEM/IMG/${assets.imageFile}`}
          target="_blank"
          rel="noopener noreferrer"
          alt=""
        >
          <img
            src={`https://assets.stellarindustrial.com/2/ITEM/IMG/${assets.imageFile}`}
            alt={altText}
            key={assets._id}
          />
        </a>
      );
    } else {
      let index = -1;
      let string = assets.map((result) => {
        index++;
        return (
          <Fragment key={uuidv4()}>
            <img
              src={`https://assets.stellarindustrial.com/2/ITEM/IMG/${result.imageFile}`}
              style={{
                maxWidth: "auto",
              }}
              alt={altText}
              key={result._id}
              className="p_small_imgs"
              onClick={(e) => onClick(e)}
              num={index}
            />
            <ImgShadow />
          </Fragment>
        );
      });

      return string;
    }
  } else {
    return "";
  }
};

ProductImg.propTypes = {
  altText: PropTypes.string.isRequired,
  single: PropTypes.string,
};

export default ProductImg;
