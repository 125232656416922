import {
  VBR_SPOXPOSTATUS_LOADING,
  VBR_SPOXPOSTATUS_ERROR,
  VBR_SPOXPOSTATUS_RESULT,
  VBR_SPOXPOSTATUS_RETURNTOP21,
  VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING,
  VBR_SPOXPOSTATUS_EMAILPOPUP_DATA,
  VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE,
} from "../actions/types";

const initialState = {
  loading: true,
  reqData: {},
  poData: {},
  emailLog: [],
  flagToReturnToP21: false,
  vbrKeyVerified: false,
  viewEmail: {
    loading: false,
    hidden: true,
    data: {},
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VBR_SPOXPOSTATUS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VBR_SPOXPOSTATUS_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case VBR_SPOXPOSTATUS_RESULT:
      const { reqData = {}, poData = {}, emailLog = [] } = payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        reqData,
        poData,
        emailLog,
        vbrKeyVerified: true,
      };
    case VBR_SPOXPOSTATUS_RETURNTOP21:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        flagToReturnToP21: true,
      };
    case VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING:
      return {
        ...state,
        viewEmail: {
          ...state.viewEmail,
          loading: true,
          hidden: false,
          data: {},
        },
      };
    case VBR_SPOXPOSTATUS_EMAILPOPUP_DATA:
      return {
        ...state,
        viewEmail: {
          ...state.viewEmail,
          loading: false,
          hidden: false,
          data: payload.data,
        },
      };
    case VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE:
      return {
        ...state,
        viewEmail: {
          ...state.viewEmail,
          hidden: true,
          data: {},
        },
      };
    default:
      return state;
  }
}
