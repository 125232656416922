export const ExtractVarValue = (_fieldName = "", stateObj) => {
  try {
    const { fieldList = [] } = stateObj;
    const desiredObj = fieldList.find((obj) => obj.fieldName === _fieldName);
    if (desiredObj === null || desiredObj === undefined) return "";

    return desiredObj.fieldValue;
  } catch (error) {
    return "";
  }
};
export const ExtractVarValues = (toExtractArr = [], stateObj = {}) => {
  try {
    const { fieldList = [] } = stateObj;

    let valuesToReturn = {};
    //grab variables out of the field list state object
    toExtractArr.forEach((desired) => {
      const desiredObj = fieldList.find((obj) => obj.fieldName === desired);
      if (desiredObj === null || desiredObj === undefined) return;
      valuesToReturn = {
        ...valuesToReturn,
        [desired]: desiredObj.fieldValue,
      };
    });

    return valuesToReturn;
  } catch (error) {
    return {};
  }
};

export const ExtractVarArrayValues = (toExtractArr = [], stateObj = {}) => {
  try {
    const { fieldList = [] } = stateObj;

    let valuesToReturn = {};
    //grab variables out of the field list state object
    toExtractArr.forEach((desired) => {
      fieldList
        .filter((obj) => obj.fieldName === desired)
        .forEach((desiredObj) => {
          if (valuesToReturn[desired] === undefined) {
            valuesToReturn[desired] = [desiredObj.fieldValue];
          } else {
            valuesToReturn[desired].push(desiredObj.fieldValue);
          }
        });
    });

    return valuesToReturn;
  } catch (error) {
    return {};
  }
};

export const ExtractVarExtValues = (toExtractArr = [], stateObj = {}) => {
  try {
    const { fieldList = [] } = stateObj;

    let valuesToReturn = {};
    //grab variables out of the field list state object
    toExtractArr.forEach((desired) => {
      const desiredObj = fieldList.find((obj) => obj.fieldName === desired);
      if (desiredObj === null || desiredObj === undefined) return;
      valuesToReturn = {
        ...valuesToReturn,
        [`${desired}_props`]: desiredObj,
      };
    });

    return valuesToReturn;
  } catch (error) {
    return {};
  }
};
