import React from "react";

const SpoxPOStatus_returnForm = (props) => {
  return (
    <form
      method="POST"
      action={`/vbr/spoxpostatus/return/${props.vbrkey}`}
      id="returnForm"
    ></form>
  );
};

export default SpoxPOStatus_returnForm;
