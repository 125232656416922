import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

const ProdFuzzyMatch_singleOverlay_sec2 = ({ search_state = {} }) => {
  const { productDetails = {} } = search_state;

  const { p21Data = {}, stellarData = {} } = productDetails;

  const { item_id = "", lastP21Pull = "" } = p21Data;

  const {
    ad = false,
    stars = false,
    metalworking_6 = false,
    safety_6 = false,
    stockable = false,
    suppliers = [],
  } = stellarData;

  return (
    <div className="sec-outer">
      <div className="secTitle">P21 / Stellar Data</div>
      <div className="secContent flex">
        <div className="flex-inner">
          <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>P21 Part Number:</td>
                <td>{item_id !== "" ? item_id : " - "}</td>
              </tr>
              <tr>
                <td>Stars Line:</td>
                <td>{stars ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>AD Line:</td>
                <td>{ad ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Metalworking 6:</td>
                <td>{metalworking_6 ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Safety 6:</td>
                <td>{safety_6 ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Stockable Item:</td>
                <td>{stockable ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Sync'd With P21:</td>
                <td>{<Moment format="M/D/Y">{lastP21Pull}</Moment>}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex-inner">
          <div className="supplier-heading">
            Stellar Suppliers For This Product:
          </div>
          {suppliers.length === 0 && (
            <div style={{ paddingTop: "5px", paddingLeft: "10px" }}>
              <i>No Suppliers Found</i>
            </div>
          )}
          {suppliers.length > 0 &&
            suppliers.map((supplier, i) => {
              const {
                supplier_id = "",
                supplier_name = "",
                supplier_part_no = "",
                supplier_ean_code = "",
                supplier_upc_code = "",
                supplier_gtin = "",
              } = supplier;
              return (
                <div className="supplier-outer" key={i}>
                  <div className="supplier-title">
                    <div>{supplier_name}</div>
                    <div>(Supplier ID: {supplier_id})</div>
                  </div>
                  <div className="supplier-inner">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>Part No:</td>
                          <td>
                            {supplier_part_no === "" ||
                            supplier_part_no === null
                              ? " - "
                              : supplier_part_no}
                          </td>
                          <td>EAN:</td>
                          <td>
                            {supplier_ean_code === "" ||
                            supplier_ean_code === null
                              ? " - "
                              : supplier_ean_code}
                          </td>
                        </tr>
                        <tr>
                          <td>UPC Code:</td>
                          <td>
                            {supplier_upc_code === "" ||
                            supplier_upc_code === null
                              ? " - "
                              : supplier_upc_code}
                          </td>
                          <td>GTIN:</td>
                          <td>
                            {supplier_gtin === "" || supplier_gtin === null
                              ? " - "
                              : supplier_gtin}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ProdFuzzyMatch_singleOverlay_sec2.propTypes = {
  search_state: PropTypes.object.isRequired,
};

export default ProdFuzzyMatch_singleOverlay_sec2;
