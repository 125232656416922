import axios from "axios";
import { setAlert } from "./alert";
import {
  VBR_SPOXPOSTATUS_LOADING,
  VBR_SPOXPOSTATUS_ERROR,
  VBR_SPOXPOSTATUS_RESULT,
  VBR_SPOXPOSTATUS_RETURNTOP21,
  VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING,
  VBR_SPOXPOSTATUS_EMAILPOPUP_DATA,
  VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE,
} from "./types";
import { v4 as uuidv4 } from "uuid";

export const loadData =
  (vbrkey = "") =>
  async (dispatch) => {
    dispatch({
      type: VBR_SPOXPOSTATUS_LOADING,
      payload: {},
    });

    //get our data from the back end
    try {
      const res = await axios.get(`/vbr/spoxpostatus/data/${vbrkey}`);

      const { data = {} } = res;
      const { reqData = {}, poData = {}, emailLog = [] } = data;

      dispatch({
        type: VBR_SPOXPOSTATUS_RESULT,
        payload: { reqData, poData, emailLog },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VBR_SPOXPOSTATUS_ERROR,
        payload: {
          error,
        },
      });
    }
  };

export const saveData =
  (vbrkey = "", reqData = {}) =>
  async (dispatch) => {
    dispatch({
      type: VBR_SPOXPOSTATUS_LOADING,
      payload: {},
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      reqData,
    };

    //get our data from the back end
    try {
      const res = await axios.put(
        `/vbr/spoxpostatus/data/${vbrkey}`,
        body,
        config
      );

      dispatch({
        type: VBR_SPOXPOSTATUS_RESULT,
        payload: res.data,
      });

      //send the user back to P21 via this dispatch
      dispatch({
        type: VBR_SPOXPOSTATUS_RETURNTOP21,
        payload: {},
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VBR_SPOXPOSTATUS_ERROR,
        payload: {
          error,
        },
      });
    }
  };

export const viewEmail = (_id) => async (dispatch) => {
  //show the popup, with loading thingy
  dispatch({
    type: VBR_SPOXPOSTATUS_EMAILPOPUP_LOADING,
    payload: {},
  });

  try {
    const res = await axios.get(`/vbr/spoxpostatus/viewEmail/${_id}`);

    dispatch({
      type: VBR_SPOXPOSTATUS_EMAILPOPUP_DATA,
      payload: res,
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      dispatch({
        type: VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE,
        payload: {},
      });
    }, 3000);
  }
};

export const hideEmail = () => async (dispatch) => {
  //turn off popup all together
  dispatch({
    type: VBR_SPOXPOSTATUS_EMAILPOPUP_HIDE,
    payload: {},
  });
};

export const sendPOEmail =
  (vbrkey = "", poNo = 0, typeOfMsg = "expedite") =>
  async (dispatch) => {
    dispatch({
      type: VBR_SPOXPOSTATUS_LOADING,
      payload: {},
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const senderRes = await axios.post(
        `/vbr/spoxpostatus/expediteEmailer/${poNo}/${typeOfMsg}`,
        {},
        config
      );

      //wait for a few seconds
      setTimeout(async () => {
        dispatch(
          setAlert(
            "Email request received, please allow up to 3 minutes",
            "success"
          )
        );

        //reload our main data so it refreshes
        reloadMainData(vbrkey, dispatch);
      }, 8000);
    } catch (error) {
      console.log(error);
      reloadMainData(vbrkey, dispatch);
    }
  };

const reloadMainData = async (vbrkey = "", dispatch) => {
  //reload our main data so it refreshes
  const res = await axios.get(`/vbr/spoxpostatus/data/${vbrkey}`);
  console.log(res);

  const { data = {} } = res;
  const { reqData = {}, poData = {}, emailLog = [] } = data;

  dispatch({
    type: VBR_SPOXPOSTATUS_RESULT,
    payload: { reqData, poData, emailLog },
  });
};
