import React, { Fragment } from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";

const SpoxPOStatus_emailLog = ({ poData, emailLog = [], onClickViewEmail }) => {
  return (
    <Fragment>
      <div
        style={{ marginBottom: "10px", fontSize: "16px", fontWeight: "bold" }}
      >
        Email Log:
      </div>
      <div className="emailLogTable">
        <table width="100%" cellSpacing="0" cellPadding="2">
          <thead>
            {emailLog.length > 0 &&
              emailLog.map((email) => {
                const { _id = "", dateSent = null, subject = "", send_status } = email;

                return (
                  <tr key={_id}>
                    <td style={{ paddingLeft: "5px" }}>
                      <Moment format="MM/DD/YY">{dateSent}</Moment>
                    </td>
                    <td>{send_status}</td>
                    <td>{subject}</td>
                    <td style={{ textAlign: "right", paddingRight: "5px" }}>
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) => onClickViewEmail(_id)}
                      >
                        View Email
                      </span>
                    </td>
                  </tr>
                );
              })}
            {emailLog.length < 1 && (
              <tr>
                <td
                  style={{
                    paddingLeft: "5px",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  No sent emails found for this PO
                </td>
              </tr>
            )}
          </thead>
        </table>
      </div>
    </Fragment>
  );
};

SpoxPOStatus_emailLog.propTypes = {};

export default SpoxPOStatus_emailLog;
