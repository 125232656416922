import {
  VBR_ITEMSEARCH_LOADING,
  VBR_ITEMSEARCH_ERROR,
  VBR_ITEMSEARCH_RESULT,
  VBR_ITEMSEARCH_RETURNTOP21,
  VBR_ITEMSEARCH_ASTYPE_SHOW,
  VBR_ITEMSEARCH_ASTYPE_HIDE,
  VBR_ITEMSEARCH_ASTYPE_CLEAR,
  VBR_ITEMSEARCH_ASTYPE_RESULT,
  VBR_ITEMSEARCH_FULLTEXT_LOADING,
  VBR_ITEMSEARCH_FULLTEXT_RESULT,
  VBR_ITEMSEARCH_LOADING_PRODDETAILS,
  VBR_ITEMSEARCH_GET_PRODDETAILS,
  VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL,
  VBR_ITEMSEARCH_GET_P21_PRICEAVAIL,
  VBR_ITEMSEARCH_GET_P21_QTY,
} from "../actions/types";

const initialState = {
  loading: true,
  reqData: {},
  flagToReturnToP21: false,
  vbrKeyVerified: false,
};

export default function (state = initialState, action) {
  const { type, payload = {} } = action;
  const { p21Info = [] } = payload;

  switch (type) {
    case VBR_ITEMSEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VBR_ITEMSEARCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case VBR_ITEMSEARCH_RESULT:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        reqData: payload,
        vbrKeyVerified: true,
      };
    case VBR_ITEMSEARCH_RETURNTOP21:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        flagToReturnToP21: true,
      };
    case VBR_ITEMSEARCH_ASTYPE_RESULT:
      return {
        ...state,
        asYouTypeArray: payload.asYouTypeArray,
        showAsYouType: true,
      };
    case VBR_ITEMSEARCH_ASTYPE_CLEAR:
      return {
        ...state,
        asYouTypeArray: [],
        showAsYouType: false,
      };
    case VBR_ITEMSEARCH_ASTYPE_SHOW:
      return {
        ...state,
        showAsYouType: true,
      };
    case VBR_ITEMSEARCH_ASTYPE_HIDE:
      return {
        ...state,
        showAsYouType: false,
      };
    case VBR_ITEMSEARCH_FULLTEXT_RESULT:
      return {
        ...state,
        fullTextLoading: false,
        searchResultsArr: payload.searchResultsArr,
        searchTerm: payload.searchTerm,
        searchPartNumber: payload.searchPartNumber,
        pag: payload.pag,
        limit: payload.limit,
        productCountTotal: payload.productCountTotal,
        searchAnalyticsID: payload.searchAnalyticsID,
      };
    case VBR_ITEMSEARCH_FULLTEXT_LOADING:
      return {
        ...state,
        fullTextLoading: true,
        searchResultsArr: [],
        searchTerm: payload.searchTerm,
        searchPartNumber: payload.searchPartNumber,
        pag: 1,
        limit: 1,
        productCountTotal: 0,
      };

    case VBR_ITEMSEARCH_LOADING_PRODDETAILS:
      return {
        ...state,
        productDetails: {
          loading: true,
        },
      };
    case VBR_ITEMSEARCH_GET_PRODDETAILS:
      return {
        ...state,
        productDetails: {
          ...payload,
          loading: false,
        },
      };

    case VBR_ITEMSEARCH_LOADING_P21_PRICEAVAIL:
    case VBR_ITEMSEARCH_GET_P21_PRICEAVAIL:
      if (payload !== undefined && p21Info !== undefined) {
        //merge the state into our new key value pairs
        for (var key of Object.keys(p21Info)) {
          //see if our state object has this same key
          if (
            state !== undefined &&
            state.p21Info !== undefined &&
            state.p21Info.hasOwnProperty(key)
          ) {
            p21Info[key] = {
              ...state.p21Info[key],
              ...p21Info[key],
            };
          }
        }
      }

      if (state !== undefined && state.p21Info !== undefined) {
        return {
          ...state,
          p21Info: {
            ...state.p21Info,
            ...p21Info,
          },
        };
      }
      if (state === undefined || state.p21Info === undefined) {
        return {
          ...state,
          p21Info: {
            ...p21Info,
          },
        };
      }
      return state;
    case VBR_ITEMSEARCH_GET_P21_QTY:
      return {
        ...state,
        p21Info: p21Info,
      };
    default:
      return state;
  }
}
