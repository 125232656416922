import { combineReducers } from "redux";
import VBRTestRule from "./vbr_testrule";
import VBRItemSearch from "./vbr_itemsearch";
import VBRSpoxPOStatus from "./vbr_spoxpostatus";
import alert from "./alert";

export default combineReducers({
  VBRTestRule,
  VBRItemSearch,
  VBRSpoxPOStatus,
  alert,
});
